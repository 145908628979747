import React, { useState, useEffect, useContext } from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import Helmet from 'react-helmet';
import {
  BREAKPOINT,
  ALIGN,
  COLOR,
  MARGIN,
  FONT_WEIGHT
} from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';
import { Heading4 } from '@latitude/heading';
import { Hero } from '@latitude/hero';
import { Link } from '@latitude/link';
import { Lframe } from '@latitude/lframe';
import { Text } from '@latitude/text';
import { Vertical } from '@latitude/spacing';
import { Metadata } from '@latitude/metadata';
import { ImportantInformation } from '@latitude/important-information';
import { Stepper } from '../../../../components/stepper';
import Layout from '@/components/layout';
import { TITLE_SUFFIX, PRODUCT_NAME } from '@/utils/constants';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { TabNavigation } from '@latitude/tab-navigation';
import { StickyNavigation } from '@latitude/sticky-navigation';

import {
  EqualMonthly,
  MinimumMonthly,
  OfferTile,
  OffersContainer,
  PLAN_TYPE_EQUAL_MONTHLY,
  PLAN_TYPE_FLEXIBLE_MONTHLY,
  PLAN_TYPE_MINIMUM_MONTHLY,
  PlanTypes
} from '@latitude/interest-free-plans';
import Partners from '../Partners/Partners';

import pageData from '@/data/pages/credit-cards/gomastercard.json';
import GoMicrositeHeader from '../_go-microsite-header';

import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useSetState } from '@/utils/hooks';

import RatesAndFees from '../_rates-and-fees';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';

const merchantData = [
  {
    id: '#harvey-norman',
    label: 'Harvey Norman',
    image: require('../Partners/images/merchant_large_harvey_norman.png')
  },
  {
    id: '#domayne',
    label: 'Domayne',
    image: require('../Partners/images/merchant_large_domayne.png')
  },
  {
    id: '#joyce-mayne',
    label: 'Joyce Mayne',
    image: require('../Partners/images/merchant_large_joyce-mayne.png')
  }
];

const Page = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location} hasSecNav>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 130px !important;
          background-color: #f8f8f8;

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/gomastercard/interest-free-offers/"
          />
          <title>Interest Free Plans With Latitude GO | Shop Partner Retailers</title>
          <meta
            name="description"
            content="Get the most out of Latitude GO Mastercard with our partners. Discover offers that help you enjoy the flexibility of long term Interest Free payment plans"
          />
        </Helmet>

        <Metadata
          title={`Interest Free Plans With Latitude GO | Shop Partner Retailers`}
          description={`Get the most out of Latitude GO Mastercard with our partners. Discover offers that help you enjoy the flexibility of long term Interest Free payment plans`}
          keywords="credit card, interest free offers, visa card"
          canonical={props.location.href}
        />
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <Box
            backgroundColor={COLOR.GREY6}
            css={`
              position: relative;
              background: #0555c8;
              padding: 85px 0;
              @media (max-width: ${BREAKPOINT.MD}) {
                padding: 25px 0;
              }
              [class^='Herostyles__HeroContainer'].HeroContent,
              .hero-default {
                background: #0555c8;
              }
              [class^='Herostyles__HeroContent'].HeroContent {
                margin: auto 0;
                background: #0555c8;
              }
              [class*='Herostyles__HeroContentInner'] h1,
              [class*='Herostyles__HeroContentInner'] p {
                color: #fff;
              }
              [class*='Herostyles__HeroFootnote'] {
                color: #fff;
                margin-top: 20px;
              }

              [class^='Herostyles__HeroContent'] {
                padding: 0;
                margin: auto 0;
              }

              @media (max-width: ${BREAKPOINT.MD}) {
                [class^='Herostyles__HeroImage'] {
                  background: #0555c8;
                }
                [class^='Herostyles__HeroContent'].HeroContent {
                  margin-bottom: 20px;
                }
              }

              @media (min-width: ${BREAKPOINT.LG}) {
                [class^='Heading__StyledComponent-'],
                h1 {
                  font-size: 40px;
                  line-height: 40px;
                }
              }
            `}
          >
            <Lframe />
            <Hero
              title="Do it your way.<br /> Interest Free."
              text="Enjoy the flexibility of a long term Interest Free payment plan with a Latitude GO Mastercard<sup>&reg;</sup> credit card."
              footnote={`Approved customers only. T&Cs, fees, charges and product exclusions apply.`}
              buttonProps={{
                href: pageData.applyNowUrl,
                children: 'Apply Now',
                trackId: 'hero-apply-now'
              }}
              imageContent={
                <div
                  css={`
                    display: flex;
                    height: 100%;
                    max-width: 517px;
                    margin: 0 auto;
                    justify-content: flex-start;
                    align-items: flex-end;

                    img {
                      width: 364px;
                      justify-content: center;

                      @media (min-width: ${BREAKPOINT.MD}) {
                        width: 625px;
                        margin: 0;
                        justify-content: flex-start;
                        margin-left: -50px;
                      }
                      @media (min-width: ${BREAKPOINT.LG}) {
                        width: 517px;
                        margin: 0;
                      }
                    }

                    @media (max-width: ${BREAKPOINT.MD}) {
                      justify-content: center;
                    }
                  `}
                >
                  <img
                    src={
                      require('./images/lp-hero-offer-banner-image-gomac.webp')
                        .default
                    }
                    alt="if"
                  />
                </div>
              }
            />
          </Box>
        )}
        <GoMicrositeHeader data={state.secondaryNavData} />
        <div
          className="d-none d-lg-block"
          css={`
            position: relative;
            z-index: 10;
            .sticky-navigation--fixed {
              top: 40px !important;
            }
          `}
        >
          <StickyNavigation
            items={[
              {
                anchor: 'available-offers',
                label: 'Available offers'
              },
              {
                anchor: 'plan-types',
                label: `Plan types`
              },
              {
                anchor: 'how-it-works',
                label: 'How it works'
              }
            ]}
            offsetElem="[data-sticky-navigation-offset]"
            trackId="sticky-get-started"
            {...state?.inPageNavData?.[0]}
          />
        </div>
        {state?.interestFreeOffersData?.[0] ? (
          <TabsInterestFreeOffers
            {...state.interestFreeOffersData[0]}
            basePath={contentfulPageData?.path}
          />
        ) : (
          <Box backgroundColor={COLOR.GREY6} id="available-offers">
            <TabsInterestFreePlans />
          </Box>
        )}
        <HorizontalRule />
        <PlanTypes
          heading=""
          types={[
            PLAN_TYPE_EQUAL_MONTHLY,
            PLAN_TYPE_MINIMUM_MONTHLY,
            PLAN_TYPE_FLEXIBLE_MONTHLY
          ]}
        />

        <HorizontalRule />
        <Partners />
        <Box
          id="how-it-works"
          css={`
            && {
              padding: 40px 0;
              @media (min-width: ${BREAKPOINT.LG}) {
                padding: 72px 0;
                background-image: url(${require('./images/benefit-pattern-left.svg')}),
                  url(${require('./images/benefit-pattern-right.svg')});
                background-size: contain;
                background-position: left center, right center;
                background-repeat: no-repeat;
              }
            }
          `}
        >
          <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
            How it works
          </Heading4>
          <Stepper
            data={[
              {
                id: 'new-card-holders',
                title: 'For new customers',
                titleHTML: `<span>For new customers</span><img src="${
                  require('./images/icon-new-cardholder.svg').default
                }" alt="New Cardholder "/>`,
                content: (
                  <Vertical spacing={MARGIN.M16}>
                    <Text fontWeight={FONT_WEIGHT.NORMAL}>
                      Hello and welcome! Sign up to a long-term, Interest Free
                      offer at Harvey Norman, Domayne or Joyce Mayne and the
                      sales team will set up your Latitude GO Mastercard
                      directly, at the point of purchase<sup>*</sup>. You can
                      also apply online,{' '}
                      <Link href={pageData.applyNowUrl}>here</Link>.
                    </Text>
                    <Text fontWeight={FONT_WEIGHT.NORMAL}>
                      Once approved and set up, you can use your Latitude GO
                      Mastercard straight away to access more great long-term,
                      Interest Free offers and for your general purchases. Just
                      upload the card to your digital wallet.
                    </Text>
                    <Text fontWeight={FONT_WEIGHT.NORMAL}>
                      We'll also send you a Latitude GO Mastercard by mail, so
                      you can pop it in your wallet for everyday use.
                    </Text>
                    <Text fontWeight={FONT_WEIGHT.NORMAL}>
                      <sup>*</sup>If approved.
                    </Text>
                  </Vertical>
                )
              },
              {
                id: 'existing-card-holders',
                title: 'For existing card holders',
                titleHTML: `<span>For existing card holders</span>
                <img src="${
                  require('./images/icon-existing-cardholder.svg').default
                }" alt="Existing Cardholder "/>`,
                content: (
                  <Vertical spacing={MARGIN.M16}>
                    <Text fontWeight={FONT_WEIGHT.NORMAL}>
                      Keep enjoying the benefits of shopping Interest Free by
                      simply presenting your Latitude GO Mastercard or your
                      driver's license at the point of your purchase. The
                      salesperson will advise you which Interest Free promotions
                      are currently available. You can then purchase up to your
                      available credit limit.
                    </Text>

                    <Text fontWeight={FONT_WEIGHT.NORMAL}>
                      Alternatively, you can shop online by using you Latitude
                      GO Mastercard on applicable Interest Free offers.
                    </Text>
                  </Vertical>
                )
              }
            ]}
          />
        </Box>

        {state?.ratesAndFeesData?.[0] ? (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        ) : (
          <RatesAndFees />
        )}

        <ImportantInformation
          data={{
            content: {
              importantInformationSectionOne: [
                'Mastercard is a registered trademark and the circles design is a trademark of Mastercard International Incorporated.<br><br><sup>1</sup> To take advantage of up to 55 days interest free on credit card general purchases, you need to pay the full closing balance (excluding un-expiring Interest Free Payment Plans) on each statement of account by the applicable due date.'
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};

const TabsInterestFreeOffers = ({ offerTabs, title, basePath }) => {
  const [activeLinkId, setActiveLinkId] = useState(null);
  const tabData = offerTabs?.map(offerTab => ({
    id: offerTab?.tabHeader?.url,
    label: offerTab?.tabHeader?.text
  }));
  const defaultLink = tabData?.[0]?.id

  const OPTIONS = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={node.data.uri}
        >
          {children}
        </Link>
      )
    }
  };

  useEffect(() => {
    setActiveFromHash();
    window.addEventListener('hashchange', setActiveFromHash, false);
  }, []);

  const setActiveFromHash = () => {
    const { hash } = location;
    if (hash && hash !== '') {
      const tabIds = tabData.map(tab => tab.id);
      setActiveLinkId(false); // unset, forces TabNavigation to rerender
      if (tabIds.includes(hash.toLowerCase())) {
        setActiveLinkId(hash.toLowerCase());
      } else {
        setActiveLinkId(defaultLink);
      }
    } else {
      setActiveLinkId(defaultLink);
    }
  };

  const onTabClickHandler = linkId => {
    setActiveLinkId(linkId);
  };

  return (
    <>
      {activeLinkId && offerTabs?.length > 1 && (
        <Box
          css={`
            border-top: 1px solid rgba(0, 0, 0, 0.13);
            padding: 0;
            max-width: 1110px;
            margin: auto;

            @media (min-width: ${BREAKPOINT.MD}) {
              border: none;
              padding-top: 40px;
            }

            p {
              background-color: white;
              position: relative;
              padding-top: 16px;
              z-index: 1;
            }

            /**
         * Selector overrides for horizontal spacing between tabs
         */

            div {
              justify-content: center;
            }

            a {
              flex: 0 1 auto;
            }
          `}
        >
          <AnalyticsLocationProvider location="Show me offers available at">
            <TabNavigation
              heading="Show me offers available at"
              backgroundColor="white"
              basepath={basePath}
              data={tabData}
              defaultActiveTabId={defaultLink}
              onTabClick={onTabClickHandler}
            />
          </AnalyticsLocationProvider>
        </Box>
      )}

      <Box.Section
        backgroundColor={COLOR.GREY6}
        css={
          offerTabs?.length > 1 && `
          /* Vertical spacing overrides */
          margin-top: -15px;

          @media (min-width: ${BREAKPOINT.MD}) {
            margin-top: -42px;
          }
        `}
      >
        {offerTabs?.map(
          offerTab =>
            activeLinkId === offerTab?.tabHeader?.url && (
              <OffersContainer heading={title? title: ""}>
                {offerTab?.tabPanel?.map(
                  ({ planType, termsConds, termsScrollToId, ...rest }) => (
                    <OfferTile
                      {...planType}
                      {...rest}
                      termsScrollToId={termsScrollToId}
                      terms={
                        !termsScrollToId && (
                          <>{documentToReactComponents(termsConds, OPTIONS)}</>
                        )
                      }
                      trackTermsEventData={{
                        location: `Offer tile - ${planType}, ${rest?.numberOf} ${rest?.frequency}, ${rest?.applicableTo}, min $${rest?.minPurchaseAmount}`
                      }}
                    />
                  )
                )}
              </OffersContainer>
            )
        )}
      </Box.Section>
    </>
  );
};

const TabsInterestFreePlans = () => {
  const [activeLinkId, setActiveLinkId] = useState(null);
  const defaultLink = '#harvey-norman';

  useEffect(() => {
    setActiveFromHash();
    window.addEventListener('hashchange', setActiveFromHash, false);
  }, []);

  const setActiveFromHash = () => {
    const { hash } = location;
    if (hash && hash !== '') {
      const tabIds = merchantData.map(tab => tab.id);
      setActiveLinkId(false); // unset, forces TabNavigation to rerender
      if (tabIds.includes(hash.toLowerCase())) {
        setActiveLinkId(hash.toLowerCase());
      } else {
        setActiveLinkId(defaultLink);
      }
    } else {
      setActiveLinkId(defaultLink);
    }
  };

  const onTabClickHandler = linkId => {
    setActiveLinkId(linkId);
  };

  return (
    <>
      {activeLinkId && (
        <Box
          css={`
            border-top: 1px solid rgba(0, 0, 0, 0.13);
            padding: 0;
            max-width: 1110px;
            margin: auto;

            @media (min-width: ${BREAKPOINT.MD}) {
              border: none;
              padding-top: 40px;
            }

            p {
              background-color: white;
              position: relative;
              padding-top: 16px;
              z-index: 1;
            }

            /**
         * Selector overrides for horizontal spacing between tabs
         */

            div {
              justify-content: center;
            }

            a {
              flex: 0 1 auto;
            }
          `}
        >
          <AnalyticsLocationProvider location="Show me offers available at">
            <TabNavigation
              heading="Show me offers available at"
              backgroundColor="white"
              basepath="/credit-cards/gomastercard/interest-free-offers/"
              data={merchantData}
              defaultActiveTabId={defaultLink}
              onTabClick={onTabClickHandler}
            />
          </AnalyticsLocationProvider>
        </Box>
      )}

      <Box.Section
        backgroundColor={COLOR.GREY6}
        css={`
          /* Vertical spacing overrides */
          margin-top: -15px;

          @media (min-width: ${BREAKPOINT.MD}) {
            margin-top: -42px;
          }
        `}
      >
        <OffersContainer heading="">
          {activeLinkId === '#harvey-norman' && <HarveyNorman />}
          {activeLinkId === '#joyce-mayne' && <JoyceMayne />}
          {activeLinkId === '#domayne' && <Domayne />}
        </OffersContainer>
      </Box.Section>
    </>
  );
};

const HarveyNorman = () => {
  return (
    <>
      {/* <EqualMonthly
        applicableTo="Storewide"
        numberOf={60}
        frequency="MONTHS"
        minPurchaseAmount={1000}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <p>
            60 Months Interest Free &mdash; No Deposit and No Interest. New
            customers must apply and be approved. Minimum Financed Amount $1000.
            Available on selected transactions made at independent Harvey Norman
            <sup>&reg;</sup> franchisees between 30/05/24 and 30/06/24. Excludes
            purchases of mobile phones, gaming consoles, gift cards, digital
            cameras and lenses, hot water system supply & installation,
            Microsoft Surface Studio products, Apple, Miele and Harvey Norman
            Customer Direct products. Excludes brands and products that are
            purchased by customers directly from suppliers at Harvey Norman
            <sup>&reg;</sup> franchisee stores. Interest free offer is available
            to customers who hold a Latitude GO Mastercard or other
            participating Latitude credit card including Latitude Gem Visa,
            CreditLine and Buyer's Edge. Total amount is payable by 60 monthly
            repayments (the exact amount of each monthly repayment is specified
            in your statement). If there is an outstanding balance after the
            interest free period ends, Latitude customers will be charged
            interest at the then current expired promotional purchases annual
            percentage rate by Latitude Finance Australia (currently 29.99% per
            annum &mdash; subject to change). A monthly account service fee of
            $10.95 applies(subject to change). Visit
            /credit-cards/gomastercard/interest-free-offers/ for more
            information on the provision of credit by Latitude Finance
            Australia.
            <strong
              css={`
                display: block;
                padding: 25px 0 0;
              `}
            >
              Bonus Gift Card Offer
            </strong>
            <p>
              From 30/05/24 to 30/06/24, customers who purchase products from a
              Harvey Norman<sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce
              Mayne<sup>&reg;</sup> franchisee using the Interest Free offer
              from Latitude Finance Australia (as described above in *1) with a
              purchase price of $1,000 or more (Eligible Purchase) will be
              eligible to receive one Harvey Norman<sup>&reg;</sup>, Domayne
              <sup>&reg;</sup>
              or Joyce Mayne<sup>&reg;</sup> gift card (Gift Card). Each Gift
              Card is issued by Derni Pty Ltd, expires 36 months from the date
              of issue and is subject to the Gift Card Terms and Conditions
              available at{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.harveynorman.com.au/gift-cards-terms-and-conditions"
              >
                www.harveynorman.com.au/gift-cards-terms-and-conditions
              </Link>
              . Gift cards may be used at any Harvey Norman<sup>&reg;</sup>,
              Domayne
              <sup>&reg;</sup> or Joyce Mayne<sup>&reg;</sup> franchisee. The
              applicable Gift Card value is based on the purchase price of the
              Eligible Purchase: spend $1,000 or more and receive a $100 Gift
              Card; spend $2,000 or more and receive a $200 Gift Card; spend
              $3,000 or more and receive a $300 Gift Card; spend $4,000 or more
              and receive a $400 Gift Card; spend $5,000 or more and receive a
              $500 Gift Card; spend $6,000 or more and receive a $600 Gift Card;
              spend $7,000 or more and receive a $700 Gift Card; spend $8,000 or
              more and receive a $800 Gift Card; spend $9,000 or more and
              receive a $900 Gift Card; spend $10,000 or more and receive a
              $1,000 Gift Card.
            </p>
          </p>
        }
        trackTermsEventData={{
          location:
            'Offer tile - Equal Monthly, 60 MONTHS, On Computer Products, min $1000'
        }}
      /> */}
      <EqualMonthly
        applicableTo="Storewide"
        numberOf={50}
        frequency="MONTHS"
        minPurchaseAmount={750}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <>
            <p>
              50 Months Interest Free &mdash; No Deposit and No Interest.
              Available from Latitude on participating credit cards. New
              customers must apply and be approved. T&Cs, Fees and Charges
              Apply#. 50 Monthly Repayments. Minimum Financed Amount $750.
              Available on selected transactions made at independent Harvey
              Norman<sup>&reg;</sup> franchisees between 01/10/24 and 31/10/24.
              Excludes purchases of mobile phones, gaming consoles, gift cards,
              digital cameras and lenses, hot water system supply &
              installation, Microsoft Surface Studio products, Apple, Miele and
              Harvey Norman Customer Direct products. Excludes brands and
              products that are purchased by customers directly from suppliers
              at Harvey Norman<sup>&reg;</sup> franchisee stores. Interest free
              offer is available to customers who hold a Latitude GO Mastercard
              or other participating Latitude credit card including Latitude Gem
              Visa, CreditLine and Buyer's Edge. Total amount is payable by 50
              monthly repayments (the exact amount of each monthly repayment is
              specified in your statement). Monthly account service fee of
              $10.95 applies (subject to change). If there is an outstanding
              balance after the interest free period ends, Latitude customers
              will be charged interest at the then current expired promotional
              purchases annual percentage rate by Latitude Finance Australia
              (currently 29.99% per annum &mdash; subject to change).
            </p>
            {/* <p>
              <strong>Bonus Gift Card Offer</strong>
            </p>
            <p>
              From 01/09/24 to 30/09/24, customers who purchase products from a
              Harvey Norman<sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce
              Mayne<sup>&reg;</sup> franchisee using the Interest Free offer
              from Latitude Finance Australia with a purchase price of $750 or
              more (Eligible Purchase) will be eligible to receive one Harvey
              Norman<sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce Mayne
              <sup>&reg;</sup>
              gift card (Gift Card). Each Gift Card is issued by Derni Pty Ltd,
              expires 50 months from the date of issue and is subject to the
              Gift Card Terms and Conditions available at{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.harveynorman.com.au/gift-cards-terms-and-conditions"
              >
                www.harveynorman.com.au/gift-cards-terms-and-conditions
              </Link>
              . Gift cards may be used at any Harvey Norman<sup>&reg;</sup>,
              Domayne<sup>&reg;</sup> or Joyce Mayne<sup>&reg;</sup>
              franchisee. The applicable Gift Card value is based on the
              purchase price of the Eligible Purchase: spend $750 or more and
              receive a $75 Gift Card; spend $1,000 or more and receive a $100
              Gift Card; spend $2,000 or more and receive a $200 Gift Card;
              spend $3,000 or more and receive a $300 Gift Card; spend $4,000 or
              more and receive a $400 Gift Card; spend $5,000 or more and
              receive a $500 Gift Card; spend $6,000 or more and receive a $600
              Gift Card; spend $7,000 or more and receive a $700 Gift Card;
              spend $8,000 or more and receive a $800 Gift Card; spend $9,000 or
              more and receive a $900 Gift Card; spend $10,000 or more and
              receive a $1,000 Gift Card.
            </p> */}
          </>
        }
        trackTermsEventData={{
          location:
            'Offer tile - Equal Monthly, 50 MONTHS, On Computer Products, min $750'
        }}
      />

      {/* <EqualMonthly
        applicableTo="On Computer Products"
        numberOf={36}
        frequency="MONTHS"
        minPurchaseAmount={500}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <>
            <p>
              36 Months Interest Free on Mobiles & Computers &mdash; No Deposit
              and No Interest. New customers must apply and be approved. Minimum
              Financed Amount $500. Available on selected transactions made at
              independent Harvey Norman<sup>&reg;</sup> franchisees between
              01/07/24 and 30/09/24. Excludes purchases of mobile phone plans,
              gaming currencies and gift cards (subject to change). Interest
              free offer is available to customers who hold a Latitude GO
              Mastercard or other participating Latitude credit card including
              Latitude Gem Visa, CreditLine and Buyer's Edge. Total amount is
              payable by 36 monthly repayments (the exact amount of each monthly
              repayment is specified in your statement). If there is an
              outstanding balance after the interest free period ends, Latitude
              customers will be charged interest at the then current expired
              promotional purchases annual percentage rate by Latitude Finance
              Australia (currently 29.99% per annum &mdash; subject to change).
              Monthly account service fee of $10.95 applies (subject to change).
            </p>
            <p>
              <strong>Bonus Gift Card Offer</strong>
            </p>
            <p>
              From 01/07/24 to 30/09/24, customers who purchase products from a
              Harvey Norman<sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce
              Mayne<sup>&reg;</sup> franchisee using the 36 Months Interest Free
              on Mobiles & Computers offer from Latitude Finance Australia with
              a purchase price of $500 or more (
              <strong>Eligible Purchase</strong>) will be eligible to receive
              one Harvey Norman
              <sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce Mayne
              <sup>&reg;</sup> gift card (<strong>Gift Card</strong>
              <sup>*</sup>). Each Gift Card is issued by Derni Pty Ltd, expires
              36 months from the date of issue and is subject to the Gift Card
              Terms and Conditions available at{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.harveynorman.com.au/gift-cards-terms-and-conditions"
              >
                www.harveynorman.com.au/gift-cards-terms-and-conditions
              </Link>
              . The applicable Gift Card value is based on the purchase price of
              the Eligible Purchase: spend $500 or more and receive a $50 Gift
              Card; spend $1,000 or more and receive a $100 Gift Card; spend
              $1,500 or more and receive a $150 Gift Card; spend $2,000 or more
              and receive a $200 Gift Card; spend $2,500 or more and receive a
              $250 Gift Card; spend $3,000 or more and receive a $300 Gift Card;
              spend $3,500 or more and receive a $350 Gift Card; spend $4,000 or
              more and receive a $400 Gift Card.
            </p>
          </>
        }
        trackTermsEventData={{
          location:
            'Offer tile - Equal Monthly, 36 MONTHS, On Computer Products, min $500'
        }}
      /> */}

      {/* <Flexible
        applicableTo="On air conditioners"
        numberOf={18}
        frequency="MONTHS"
        minFinancedAmount={500}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <p>
            Conditions of No Deposit, No Interest, No Payments for 18 Months on
            Air Conditioners only: Available to approved Latitude GO Mastercard
            customers on transactions made between 29/03/23 and 30/04/24, where
            the amount financed is $500 or more. Offer available on purchases
            from Harvey Norman franchisees. Offer available on advertised or
            ticketed price. If there is an outstanding balance after the
            interest free period ends, interest will be charged at 29.99% p.a.
            This notice is given under the Latitude GO Mastercard Conditions of
            Use, which specify all other conditions for this offer. Account
            Service fee of $9.95 per month applies (subject to change). Also
            available to existing CreditLine, Latitude Gem Visa and Buyer's Edge
            customers. Refer to product websites for conditions, fees and
            charges. Credit is provided by Latitude Finance Australia (ABN 42
            008 583 588). Australian Credit Licence 392145.
          </p>
        }
        trackTermsEventData={{
          location: 'Offer tile - Flex, 18 MONTHS, air conditioners, min $300'
        }}
      /> */}
      {/* <Flexible
        applicableTo="On cooking appliances"
        numberOf={12}
        frequency="MONTHS"
        minFinancedAmount={300}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <p>
            Conditions of No Deposit, No Interest, No Payments for 12 Months on
            Cooking Appliances. Available to approved Latitude GO Mastercard
            customers on transactions made between 29/03/23 and 30/04/24 where
            the amount financed is $300 or more. Offer available on purchases
            from Harvey Norman franchisees (excludes Miele products. Also
            excludes brands and other products that are offered for sale under
            agency agreements with Harvey Norman Franchisees). Offer available
            on advertised or ticketed price. If there is an outstanding balance
            after the interest free period ends, interest will be charged at
            29.99% p.a. This notice is given under the Latitude GO Mastercard
            Conditions of Use, which specify all other conditions for this
            offer. account service fee of $10.95 per month applies (subject to
            change). Also available to existing CreditLine, Latitude Gem Visa
            and Buyer's Edge customers. Refer to product websites for
            conditions, fees and charges. Credit is provided by Latitude Finance
            Australia (ABN 42 008 583 588). Australian Credit Licence 392145.
          </p>
        }
        trackTermsEventData={{
          location: 'Offer tile - Flex, 12 MONTHS, cooking appliances, min $300'
        }}
      /> */}
      <MinimumMonthly
        applicableTo="Storewide"
        numberOf={6}
        frequency="MONTHS"
        minPurchaseAmount={150}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <>
            <p>
              Conditions of No Deposit, No Interest with Monthly Payments for 6
              months: Available to approved Latitude GO Mastercard customers on
              transactions where the amount financed is $150 or more. Offer
              available on purchases from Harvey Norman franchisees (excludes
              gift cards, iTunes cards and Miele products. Also excludes brands
              and other products that are offered for sale under agency
              agreements with Harvey Norman Franchisees). Minimum monthly
              payments must be made during the interest free period, and
              interest and payments are payable after the interest free period.
              Paying only the minimum monthly payment will not pay out the
              balance before the end of the interest free period. If there is an
              outstanding balance after the interest free period ends, interest
              will be charged at 29.99% p.a. This notice is given under the
              Latitude GO Mastercard Conditions of Use, which specify all other
              conditions for this offer. Account Service fee of $10.95 per month
              applies (subject to change). Also available to existing
              CreditLine, Latitude Gem Visa and Buyer's Edge customers. Refer to
              product websites for conditions, fees and charges. Credit is
              provided by Latitude Finance Australia (ABN 42 008 583 588).
              Australian Credit Licence 392145.
            </p>
          </>
        }
        trackTermsEventData={{
          location: 'Offer tile - Min monthly, 6 MONTHS, storewide, min $300'
        }}
      />
    </>
  );
};

const Domayne = () => {
  return (
    <>
      {/* <EqualMonthly
        applicableTo="Storewide"
        numberOf={60}
        frequency="MONTHS"
        minPurchaseAmount={1500}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <p>
            New customers must apply and be approved. Minimum Financed Amount
            $1,500. Available on selected transactions made at independent
            Domayne<sup>&reg;</sup> franchisees between 07/03/23 and 29/04/24.
            Excludes purchases of mobile phones, gaming consoles, gift cards,
            digital cameras and lenses, hot water system supply &amp;
            installation, Microsoft Surface Studio products, Apple, Miele and
            Domayne Hire purchases. Excludes brands and products that are
            purchased by customers directly from suppliers at Domayne
            <sup>&reg;</sup> franchisee stores. Interest free offer is available
            to customers who hold a Latitude GO Mastercard or other
            participating Latitude credit card including Latitude Gem Visa,
            CreditLine and Buyer's Edge. Total amount is payable by 60 monthly
            repayments (the exact amount of each monthly repayment is specified
            in your statement). If there is an outstanding balance after the
            interest free period ends, Latitude customers will be charged
            interest at the then current expired promotional purchases annual
            percentage rate by Latitude Finance Australia (currently 29.99% per
            annum &mdash; subject to change). Monthly account service fee of
            $9.95 applies (subject to change).
            <strong
              css={`
                display: block;
                padding: 25px 0 0;
              `}
            >
              Bonus Gift Card Offer
            </strong>
            <p>
              From 07/03/24 to 29/04/24, customers who purchase products from a
              Harvey Norman<sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce
              Mayne<sup>&reg;</sup> franchisee using the Interest Free offer
              from Latitude Finance Australia (as described above in *1) with a
              purchase price of $1,500 or more (
              <strong>Eligible Purchase</strong>) will be eligible to receive
              one Harvey Norman
              <sup>&reg;</sup>, Domayne<sup>&reg;</sup>
              or Joyce Mayne<sup>&reg;</sup> gift card (
              <strong>Gift Card</strong>). Each Gift Card is issued by Derni Pty
              Ltd, expires 36 months from the date of issue and is subject to
              the Gift Card Terms and Conditions available at{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.domayne.com.au/gift-cards-terms-and-conditions"
              >
                www.domayne.com.au/gift-cards-terms-and-conditions
              </Link>
              . Gift cards may be used at any Harvey Norman<sup>&reg;</sup>,
              Domayne
              <sup>&reg;</sup> or Joyce Mayne<sup>&reg;</sup>
              franchisee. The applicable Gift Card value is based on the
              purchase price of the Eligible Purchase: spend $1,500 or more and
              receive a $150 Gift Card; spend $2,000 or more and receive a $200
              Gift Card; spend $3,000 or more and receive a $300 Gift Card;
              spend $4,000 or more and receive a $400 Gift Card; spend $5,000 or
              more and receive a $500 Gift Card; spend $6,000 or more and
              receive a $600 Gift Card; spend $7,000 or more and receive a $700
              Gift Card; spend $8,000 or more and receive a $800 Gift Card;
              spend $9,000 or more and receive a $900 Gift Card; spend $10,000
              or more and receive a $1,000 Gift Card.
            </p>
          </p>
        }
        trackTermsEventData={{
          location:
            'Offer tile - Equal Monthly, 60 MONTHS, On Computer Products, min $1000'
        }}
      /> */}
      <EqualMonthly
        applicableTo="Storewide"
        numberOf={50}
        frequency="MONTHS"
        minPurchaseAmount={750}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <>
            <p>
              50 Months Interest Free &mdash; No Deposit and No Interest.
              Available from Latitude on participating credit cards#. New
              customers must apply and be approved. T&Cs, Fees and Charges
              Apply#. 50 Monthly Repayments. Minimum Financed Amount $750.
              Available on selected transactions made at independent Domayne
              <sup>&reg;</sup> franchisees between 01/10/24 and 31/10/24.
              Excludes purchases of mobile phones, gaming consoles, gift cards,
              digital cameras and lenses, hot water system supply &
              installation, Microsoft Surface Studio products, Apple, Miele and
              Domayne Hire purchases. Excludes brands and products that are
              purchased by customers directly from suppliers at Domayne
              <sup>&reg;</sup> franchisee stores. Interest free offer is
              available to customers who hold a Latitude GO Mastercard or other
              participating Latitude credit card including Latitude Gem Visa,
              CreditLine and Buyer's Edge. Total amount is payable by 50 monthly
              repayments (the exact amount of each monthly repayment is
              specified in your statement). Monthly account service fee of
              $10.95 applies (subject to change). If there is an outstanding
              balance after the interest free period ends, Latitude customers
              will be charged interest at the then current expired promotional
              purchases annual percentage rate by Latitude Finance Australia
              (currently 29.99% per annum &mdash; subject to change).
            </p>
            {/* <p>
              <strong>Bonus Gift Card Offer</strong>
            </p>
            <p>
              From 01/09/24 to 30/09/24, customers who purchase products from a
              Harvey Norman<sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce
              Mayne<sup>&reg;</sup> franchisee using the Interest Free offer
              from Latitude Finance Australia with a purchase price of $750 or
              more (Eligible Purchase) will be eligible to receive one Harvey
              Norman<sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce Mayne
              <sup>&reg;</sup>
              gift card (Gift Card). Each Gift Card is issued by Derni Pty Ltd,
              expires 50 months from the date of issue and is subject to the
              Gift Card Terms and Conditions available at{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.domayne.com.au/gift-cards-terms-and-conditions"
              >
                www.domayne.com.au/gift-cards-terms-and-conditions
              </Link>
              . Gift cards may be used at any Harvey Norman<sup>&reg;</sup>,
              Domayne<sup>&reg;</sup> or Joyce Mayne<sup>&reg;</sup>
              franchisee. The applicable Gift Card value is based on the
              purchase price of the Eligible Purchase: spend $750 or more and
              receive a $75 Gift Card; spend $1,000 or more and receive a $100
              Gift Card; spend $2,000 or more and receive a $200 Gift Card;
              spend $3,000 or more and receive a $300 Gift Card; spend $4,000 or
              more and receive a $400 Gift Card; spend $5,000 or more and
              receive a $500 Gift Card; spend $6,000 or more and receive a $600
              Gift Card; spend $7,000 or more and receive a $700 Gift Card;
              spend $8,000 or more and receive a $800 Gift Card; spend $9,000 or
              more and receive a $900 Gift Card; spend $10,000 or more and
              receive a $1,000 Gift Card.
            </p> */}
          </>
        }
        trackTermsEventData={{
          location:
            'Offer tile - Equal Monthly, 50 MONTHS, On Computer Products, min $750'
        }}
      />

      {/* <EqualMonthly
        applicableTo="On Computer Products"
        numberOf={36}
        frequency="MONTHS"
        minPurchaseAmount={500}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <>
            <p>
              36 Months Interest Free on Mobiles & Computers &mdash; No Deposit
              and No Interest. New customers must apply and be approved. Minimum
              Financed Amount $500. Available on selected transactions made at
              independent Domayne<sup>&reg;</sup> franchisees between 01/07/24
              to 30/09/24. Excludes purchases of mobile phone plans, gaming
              currencies and gift cards (subject to change). Interest free offer
              is available to customers who hold a Latitude GO Mastercard or
              other participating Latitude credit card including Latitude Gem
              Visa, CreditLine and Buyer's Edge. Total amount is payable by 36
              monthly repayments (the exact amount of each monthly repayment is
              specified in your statement). If there is an outstanding balance
              after the interest free period ends, Latitude customers will be
              charged interest at the then current expired promotional purchases
              annual percentage rate by Latitude Finance Australia (currently
              29.99% per annum &mdash; subject to change). Monthly account
              service fee of $10.95 applies (subject to change).
            </p>
            <p>
              <strong>Bonus Gift Card Offer</strong>
            </p>
            <p>
              From 01/07/24 to 30/09/24, customers who purchase products from a
              Harvey Norman<sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce
              Mayne<sup>&reg;</sup> franchisee using the 36 Months Interest Free
              on Mobiles & Computers offer from Latitude Finance Australia (as
              described above in *1) with a purchase price of $500 or more
              (Eligible Purchase) will be eligible to receive one Harvey Norman
              <sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce Mayne
              <sup>&reg;</sup> gift card (Gift Card
              <sup>*</sup>). Each Gift Card is issued by Derni Pty Ltd, expires
              36 months from the date of issue and is subject to the Gift Card
              Terms and Conditions available at{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.domayne.com.au/gift-cards-terms-and-conditions"
              >
                www.domayne.com.au/gift-cards-terms-and-conditions
              </Link>
              . The applicable Gift Card value is based on the purchase price of
              the Eligible Purchase: spend $500 or more and receive a $50 Gift
              Card; spend $1,000 or more and receive a $100 Gift Card; spend
              $1,500 or more and receive a $150 Gift Card; spend $2,000 or more
              and receive a $200 Gift Card; spend $2,500 or more and receive a
              $250 Gift Card; spend $3,000 or more and receive a $300 Gift Card;
              spend $3,500 or more and receive a $350 Gift Card; spend $4,000 or
              more and receive a $400 Gift Card.
            </p>
          </>
        }
        trackTermsEventData={{
          location:
            'Offer tile - Equal Monthly, 36 MONTHS, On Computer Products, min $500'
        }}
      /> */}

      {/* <Flexible
        applicableTo="On air conditioners"
        numberOf={18}
        frequency="MONTHS"
        minFinancedAmount={500}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <>
            Conditions of No Deposit, No Interest, No Payments for 18 Months on
            Air Conditioners only: Available to approved Latitude GO Mastercard
            customers on transactions made between 29/03/23 and 30/04/24, where
            the amount financed is $500 or more. Offer available on purchases
            from Domayne franchisees. Offer available on advertised or ticketed
            price. If there is an outstanding balance after the interest free
            period ends, interest will be charged at 29.99% p.a. This notice is
            given under the Latitude GO Mastercard Conditions of Use, which
            specify all other conditions for this offer. Account Service fee of
            $9.95 per month applies (subject to change). Also available to
            existing CreditLine, Latitude Gem Visa and Buyer's Edge customers.
            Refer to product websites for conditions, fees and charges. Credit
            is provided by Latitude Finance Australia (ABN 42 008 583 588).
            Australian Credit Licence 392145.
          </>
        }
        trackTermsEventData={{
          location: 'Offer tile - Flex, 18 MONTHS, air conditioners, min $300'
        }}
      /> */}
      {/* <Flexible
        applicableTo="On cooking appliances"
        numberOf={12}
        frequency="MONTHS"
        minFinancedAmount={300}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <>
            Conditions of No Deposit, No Interest, No Payments for 12 Months on
            Cooking Appliances. Available to approved Latitude GO Mastercard
            customers on transactions made between 29/03/23 and 30/04/24 where
            the amount financed is $300 or more. Offer available on purchases
            from Domayne franchisees (excludes Miele products. Also excludes
            brands and other products that are offered for sale under agency
            agreements with Domayne Franchisees). Offer available on advertised
            or ticketed price. If there is an outstanding balance after the
            interest free period ends, interest will be charged at 29.99% p.a.
            This notice is given under the Latitude GO Mastercard Conditions of
            Use, which specify all other conditions for this offer. Account
            Service fee of $9.95 per month applies (subject to change). Also
            available to existing CreditLine, Latitude Gem Visa and Buyer's Edge
            customers. Refer to product websites for conditions, fees and
            charges. Credit is provided by Latitude Finance Australia (ABN 42
            008 583 588). Australian Credit Licence 392145.
            </>
        }
        trackTermsEventData={{
          location: 'Offer tile - Flex, 12 MONTHS, cooking appliances, min $300'
        }}
      /> */}
      <MinimumMonthly
        applicableTo="Storewide"
        numberOf={6}
        frequency="MONTHS"
        minPurchaseAmount={300}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <>
            Conditions of No Deposit, No Interest with Monthly Payments for 6
            months: Available to approved Latitude GO Mastercard customers on
            transactions where the amount financed is $150 or more. Offer
            available on purchases from Domayne franchisees (excludes gift
            cards, iTunes cards and Miele products. Also excludes brands and
            other products that are offered for sale under agency agreements
            with Domayne Franchisees). Minimum monthly payments must be made
            during the interest free period, and interest and payments are
            payable after the interest free period. Paying only the minimum
            monthly payment will not pay out the balance before the end of the
            interest free period. If there is an outstanding balance after the
            interest free period ends, interest will be charged at 29.99% p.a.
            This notice is given under the Latitude GO Mastercard Conditions of
            Use, which specify all other conditions for this offer. Account
            Service fee of $10.95 per month applies (subject to change). Also
            available to existing CreditLine, Latitude Gem Visa and Buyer's Edge
            customers. Refer to product websites for conditions, fees and
            charges. Credit is provided by Latitude Finance Australia (ABN 42
            008 583 588). Australian Credit Licence 392145.
          </>
        }
        trackTermsEventData={{
          location: 'Offer tile - Min monthly, 6 MONTHS, storewide, min $300'
        }}
      />
    </>
  );
};

const JoyceMayne = () => {
  return (
    <>
      {/* <EqualMonthly
        applicableTo="Storewide"
        numberOf={60}
        frequency="MONTHS"
        minPurchaseAmount={1500}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <p>
            New customers must apply and be approved. Minimum Financed Amount
            $1,500. Available on selected transactions made at independent Joyce
            Mayne<sup>&reg;</sup> franchisees between 07/03/24 and 29/04/24.
            Excludes purchases of mobile phones, gaming consoles, gift cards,
            digital cameras and lenses, hot water system supply &amp;
            installation, Microsoft Surface Studio products, Apple and Miele
            products. Excludes brands and products that are purchased by
            customers directly from suppliers at Joyce Mayne<sup>&reg;</sup>{' '}
            franchisee stores. Interest free offer is available to customers who
            hold a Latitude GO Mastercard or other participating Latitude credit
            card including Latitude Gem Visa, CreditLine and Buyer's Edge. Total
            amount is payable by 60 monthly repayments (the exact amount of each
            monthly repayment is specified in your statement). If there is an
            outstanding balance after the interest free period ends, Latitude
            customers will be charged interest at the then current expired
            promotional purchases annual percentage rate by Latitude Finance
            Australia (currently 29.99% per annum &mdash; subject to change).
            Monthly account service fee of $10.95 applies (subject to change).
            <strong
              css={`
                display: block;
                padding: 25px 0 0;
              `}
            >
              Bonus Gift Card Offer
            </strong>
            <p>
              From 07/03/24 to 29/04/24, customers who purchase products from a
              Harvey Norman<sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce
              Mayne<sup>&reg;</sup> franchisee using the Interest Free offer
              from Latitude Finance Australia (as described above in *1) with a
              purchase price of $1,500 or more (
              <strong>Eligible Purchase</strong>) will be eligible to receive
              one Harvey Norman<sup>&reg;</sup>, Domayne
              <sup>&reg;</sup> or Joyce Mayne<sup>&reg;</sup> gift card (
              <strong>Gift Card</strong>). Each Gift Card is issued by Derni Pty
              Ltd, expires 36 months from the date of issue and is subject to
              the Gift Card Terms and Conditions available at{' '}
              <Link href="https://www.joycemayne.com.au/gift-cards-terms-and-conditions">
                www.joycemayne.com.au/gift-cards-terms-and-conditions
              </Link>
              . Gift cards may be used at any Harvey Norman<sup>&reg;</sup>,
              Domayne<sup>&reg;</sup> or Joyce Mayne<sup>&reg;</sup> franchisee.
              The applicable Gift Card value is based on the purchase price of
              the Eligible Purchase: spend $1,500 or more and receive a $150
              Gift Card; spend $2,000 or more and receive a $200 Gift Card;
              spend $3,000 or more and receive a $300 Gift Card; spend $4,000 or
              more and receive a $400 Gift Card; spend $5,000 or more and
              receive a $500 Gift Card; spend $6,000 or more and receive a $600
              Gift Card; spend $7,000 or more and receive a $700 Gift Card;
              spend $8,000 or more and receive a $800 Gift Card; spend $9,000 or
              more and receive a $900 Gift Card; spend $10,000 or more and
              receive a $1,000 Gift Card.
            </p>
          </p>
        }
        trackTermsEventData={{
          location:
            'Offer tile - Equal Monthly, 60 MONTHS, On Computer Products, min $1000'
        }}
      /> */}
      <EqualMonthly
        applicableTo="Storewide"
        numberOf={50}
        frequency="MONTHS"
        minPurchaseAmount={750}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <>
            <p>
              50 Months Interest Free &mdash; No Deposit and No Interest.
              Available from Latitude on participating credit cards#. New
              customers must apply and be approved. T&Cs, Fees and Charges
              Apply. 50 Monthly Repayments. Minimum Financed Amount $750.
              Available on selected transactions made at independent Joyce Mayne
              <sup>&reg;</sup> franchisees between 01/10/24 and 31/10/24.
              Excludes purchases of mobile phones, gaming consoles, gift cards,
              digital cameras and lenses, hot water system supply &
              installation, Microsoft Surface Studio products, Apple and Miele
              products. Excludes brands and products that are purchased by
              customers directly from suppliers at Joyce Mayne<sup>&reg;</sup>
              franchisee stores. Interest free offer is available to customers
              who hold a Latitude GO Mastercard or other participating Latitude
              credit card including Latitude Gem Visa, CreditLine and Buyer's
              Edge. Total amount is payable by 50 monthly repayments (the exact
              amount of each monthly repayment is specified in your statement).
              Monthly account service fee of $10.95 applies (subject to change).
              If there is an outstanding balance after the interest free period
              ends, Latitude customers will be charged interest at the then
              current expired promotional purchases annual percentage rate by
              Latitude Finance Australia (currently 29.99% per annum &mdash;
              subject to change).
            </p>
            {/* <p>
              <strong>Bonus Gift Card Offer</strong>
            </p>
            <p>
              From 01/09/24 to 30/09/24, customers who purchase products from a
              Harvey Norman<sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce
              Mayne<sup>&reg;</sup> franchisee using the Interest Free offer
              from Latitude Finance Australia with a purchase price of $7500 or
              more (Eligible Purchase) will be eligible to receive one Harvey
              Norman<sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce Mayne
              <sup>&reg;</sup>
              gift card (Gift Card). Each Gift Card is issued by Derni Pty Ltd,
              expires 50 months from the date of issue and is subject to the
              Gift Card Terms and Conditions available at{' '}
              <Link href="https://www.joycemayne.com.au/gift-cards-terms-and-conditions">
                www.joycemayne.com.au/gift-cards-terms-and-conditions
              </Link>
              . Gift cards may be used at any Harvey Norman<sup>&reg;</sup>,
              Domayne<sup>&reg;</sup> or Joyce Mayne<sup>&reg;</sup> franchisee.
              The applicable Gift Card value is based on the purchase price of
              the Eligible Purchase: spend $750 or more and receive a $75 Gift
              Card; spend $1,000 or more and receive a $100 Gift Card; spend
              $2,000 or more and receive a $200 Gift Card; spend $3,000 or more
              and receive a $300 Gift Card; spend $4,000 or more and receive a
              $400 Gift Card; spend $5,000 or more and receive a $500 Gift Card;
              spend $6,000 or more and receive a $600 Gift Card; spend $7,000 or
              more and receive a $700 Gift Card; spend $8,000 or more and
              receive a $800 Gift Card; spend $9,000 or more and receive a $900
              Gift Card; spend $10,000 or more and receive a $1,000 Gift Card.
            </p> */}
          </>
        }
        trackTermsEventData={{
          location:
            'Offer tile - Equal Monthly, 50 MONTHS, On Computer Products, min $750'
        }}
      />

      {/* <EqualMonthly
        applicableTo="On Computer Products"
        numberOf={36}
        frequency="MONTHS"
        minPurchaseAmount={500}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <>
            <p>
              36 Months Interest Free on Mobiles & Computers &mdash; No Deposit
              and No Interest. New customers must apply and be approved. Minimum
              Financed Amount $500. Available on selected transactions made at
              independent Joyce Mayne<sup>&reg;</sup> franchisees between
              01/07/24 and 30/09/24. Excludes purchases of mobile phone plans,
              gaming currencies and gift cards (subject to change). Interest
              free offer is available to customers who hold a Latitude GO
              Mastercard or other participating Latitude credit card including
              Latitude Gem Visa, CreditLine and Buyer's Edge. Total amount is
              payable by 36 monthly repayments (the exact amount of each monthly
              repayment is specified in your statement). If there is an
              outstanding balance after the interest free period ends, Latitude
              customers will be charged interest at the then current expired
              promotional purchases annual percentage rate by Latitude Finance
              Australia (currently 29.99% per annum &mdash; subject to change).
              Monthly account service fee of $10.95 applies (subject to change).
            </p>
            <p>
              <strong>Bonus Gift Card Offer</strong>
            </p>
            <p>
              From 01/07/24 to 30/09/24, customers who purchase products from a
              Harvey Norman<sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce
              Mayne<sup>&reg;</sup> franchisee using the 36 Months Interest Free
              on Mobiles & Computers offer from Latitude Finance Australia (as
              described above in *1) with a purchase price of $500 or more
              (Eligible Purchase) will be eligible to receive one Harvey Norman
              <sup>&reg;</sup>, Domayne<sup>&reg;</sup> or Joyce Mayne
              <sup>&reg;</sup> gift card (Gift Card
              <sup>*</sup>). Each Gift Card is issued by Derni Pty Ltd, expires
              36 months from the date of issue and is subject to the Gift Card
              Terms and Conditions available at{' '}
              <Link href="https://www.joycemayne.com.au/gift-cards-terms-and-conditions">
                www.joycemayne.com.au/gift-cards-terms-and-conditions
              </Link>
              . The applicable Gift Card value is based on the purchase price of
              the Eligible Purchase: spend $500 or more and receive a $50 Gift
              Card; spend $1,000 or more and receive a $100 Gift Card; spend
              $1,500 or more and receive a $150 Gift Card; spend $2,000 or more
              and receive a $200 Gift Card; spend $2,500 or more and receive a
              $250 Gift Card; spend $3,000 or more and receive a $300 Gift Card;
              spend $3,500 or more and receive a $350 Gift Card; spend $4,000 or
              more and receive a $400 Gift Card.
            </p>
          </>
        }
        trackTermsEventData={{
          location:
            'Offer tile - Equal Monthly, 36 MONTHS, On Computer Products, min $500'
        }}
      /> */}

      {/* <Flexible
        applicableTo="On air conditioners"
        numberOf={18}
        frequency="MONTHS"
        minFinancedAmount={500}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <>
            Conditions of No Deposit, No Interest, No Payments for 18 Months on
            Air Conditioners only: Available to approved Latitude GO Mastercard
            customers on transactions made between 29/03/23 and 30/04/24, where
            the amount financed is $500 or more. Offer available on purchases
            from Joyce Mayne franchisees. Offer available on advertised or
            ticketed price. If there is an outstanding balance after the
            interest free period ends, interest will be charged at 29.99% p.a.
            This notice is given under the Latitude GO Mastercard Conditions of
            Use, which specify all other conditions for this offer. Account
            Service fee of $9.95 per month applies (subject to change). Also
            available to existing CreditLine, Latitude Gem Visa and Buyer's Edge
            customers. Refer to product websites for conditions, fees and
            charges. Credit is provided by Latitude Finance Australia (ABN 42
            008 583 588). Australian Credit Licence 392145.
          </>
        }
        trackTermsEventData={{
          location: 'Offer tile - Flex, 18 MONTHS, air conditioners, min $300'
        }}
      /> */}
      {/* <Flexible
        applicableTo="On cooking appliances"
        numberOf={12}
        frequency="MONTHS"
        minFinancedAmount={300}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <>
            Conditions of No Deposit, No Interest, No Payments for 12 Months on
            Cooking Appliances. Available to approved Latitude GO Mastercard
            customers on transactions made between 29/03/23 and 30/04/24 where
            the amount financed is $300 or more. Offer available on purchases
            from Joyce Mayne franchisees (excludes Miele products. Also excludes
            brands and other products that are offered for sale under agency
            agreements with Joyce Mayne Franchisees). Offer available on
            advertised or ticketed price. If there is an outstanding balance
            after the interest free period ends, interest will be charged at
            29.99% p.a. This notice is given under the Latitude GO Mastercard
            Conditions of Use, which specify all other conditions for this
            offer. account service fee of $10.95 per month applies (subject to
            change). Also available to existing CreditLine, Latitude Gem Visa
            and Buyer's Edge customers. Refer to product websites for
            conditions, fees and charges. Credit is provided by Latitude Finance
            Australia (ABN 42 008 583 588). Australian Credit Licence 392145.
          </>
        }
        trackTermsEventData={{
          location: 'Offer tile - Flex, 12 MONTHS, cooking appliances, min $300'
        }}
      /> */}
      <MinimumMonthly
        applicableTo="Storewide"
        numberOf={6}
        frequency="MONTHS"
        minPurchaseAmount={300}
        tags="harvey-norman domayne joyce-mayne"
        terms={
          <>
            Conditions of No Deposit, No Interest with Monthly Payments for 6
            months: Available to approved Latitude GO Mastercard customers on
            transactions where the amount financed is $150 or more. Offer
            available on purchases from Joyce Mayne franchisees (excludes gift
            cards, iTunes cards and Miele products. Also excludes brands and
            other products that are offered for sale under agency agreements
            with Joyce Mayne Franchisees). Minimum monthly payments must be made
            during the interest free period, and interest and payments are
            payable after the interest free period. Paying only the minimum
            monthly payment will not pay out the balance before the end of the
            interest free period. If there is an outstanding balance after the
            interest free period ends, interest will be charged at 29.99% p.a.
            This notice is given under the Latitude GO Mastercard Conditions of
            Use, which specify all other conditions for this offer. Account
            Service fee of $10.95 per month applies (subject to change). Also
            available to existing CreditLine, Latitude Gem Visa and Buyer's Edge
            customers. Refer to product websites for conditions, fees and
            charges. Credit is provided by Latitude Finance Australia (ABN 42
            008 583 588). Australian Credit Licence 392145.
          </>
        }
        trackTermsEventData={{
          location: 'Offer tile - Min monthly, 6 MONTHS, storewide, min $300'
        }}
      />
    </>
  );
};

export default Page;
