import React from 'react';
import { COLOR } from '@latitude/core/utils/constants';
import RatesAndFeesComponent from '@latitude/rates-and-fees';
import { Link } from '@latitude/link';

const RatesAndFees = () => (
  <div id="rates-and-fees">
    <RatesAndFeesComponent
      id="rates"
      css={`
        button.link-block {
          background-color: ${COLOR.GREY5};
          cursor: pointer;
        }
        .accordion-custom .accordion__row {
          padding: 5px 0;
          margin: 0 30px;
        }
      `}
      title="Rates & Fees"
      ratesListProps={{
        data: [
          {
            largeText: '0',
            showExtras: true,
            smallText: 'on long-term Interest Free Payment Plans'
          },
          {
            largeText: '0',
            showExtras: true,
            smallText:
              'up to 55 days interest free<sup>1</sup> on general purchases'
          },
          {
            largeText: '27.99',
            showExtras: true,
            smallText: 'Purchase rate on general purchases'
          },
          {
            largeText: '$0',
            smallText: 'account establishment fee'
          },
          {
            largeText: '$10.95',
            smallText: 'credit card fee charged monthly (subject to change)'
          }
        ]
      }}
      accordionProps={{
        items: [
          {
            id: 'other-fees',
            title: 'Other rates, fees and conditions',
            content: (
              <div className="accordion-custom">
                <div className="d-lg-flex accordion__row">
                  <p className="w-100 pr-3">
                    <strong>
                      Min credit limit
                    </strong>
                  </p>
                  <p className="w-100">$1000</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100 pr-3">
                    <strong>Expired Plan Rate</strong>
                  </p>
                  <p className="w-100">29.99% p.a.</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Cash Advance Rate </strong>
                  </p>
                  <p className="w-100">29.99% p.a.</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100 pr-3">
                    <strong>Balance Transfer Rate</strong>
                  </p>
                  <p className="w-100">
                    The Cash Advance Rate applies, unless a specific balance transfer rate is subsequently offered.  
                  </p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100 pr-3">
                    <strong>Cash advance fee</strong>
                  </p>
                  <p className="w-100">
                    The greater of 3.5% or $4.  
                  </p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Payment handling fees</strong>
                  </p>

                  <ul css="width:100%; margin-top:0; padding-left: 17px;">
                    <li>
                      $0 when you pay via the Latitude App, Latitude Service
                      Centre, mail, or direct debit
                    </li>
                    <li>
                      $1.95 (online using BPAY<sup>®</sup>)
                    </li>
                    <li>$3.95 (in person at Australia Post)</li>
                  </ul>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Late fee</strong>
                  </p>
                  <p className="w-100">$45</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Paper statements</strong>
                  </p>
                  <p className="w-100">
                    $5.00 <br />
                    You can avoid this fee by opting to receive statements
                    online. Simply login to the Latitude Service Centre.
                  </p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100 pr-3">
                    <strong>Balance Transfer fee</strong>
                  </p>
                  <p className="w-100">Up to 3% of balance transfer amount</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100 pr-3">
                    <strong>International transaction fee</strong>
                  </p>
                  <p className="w-100">3% on all purchases and cash advances</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100 pr-3">
                    <strong>International cash advance fee</strong>
                  </p>
                  <p className="w-100">$4, on top of the cash advance fee</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100 pr-3">
                    <strong>International cash over the counter fee</strong>
                  </p>
                  <p className="w-100">$5, on top of the cash advance fee</p>
                </div>

              </div>
            )
          },

          {
            id: 'other-information',
            title: 'Other information',
            content: (
              <div className="accordion-custom">
                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    Before making a decision, please read&nbsp;  
                    <Link href="https://assets.latitudefinancial.com/legals/credit-card-general-terms-and-conditions.pdf">
                      Latitude Credit Card General Terms and Conditions
                    </Link> and&nbsp;  
                    <Link href="https://assets.latitudefinancial.com/legals/key-fact-sheet/gomc/kfs.html">
                      Latitude GO Mastercard Key Facts Sheet
                    </Link>&nbsp;to
                    ensure you&apos;re aware of all fees, charges and interest
                    rates.
                  </p>
                </div>
              </div>
            )
          }
        ]
      }}
    />
  </div>
);

export default RatesAndFees;
